<template>
	<div class="pentathlon">
		<SubHeader title="Members 2022" subtitle="2022年度 会員リスト" />

		<div class="container">
			<div class="row justify-content-center">
				<div class="col-lg-12 mb-3">
					<ul class="nav nav-pills mb-3" id="myTab" role="tablist">
						<li class="nav-item">
							<a class="nav-link" href="/members2024"> 2024年度 </a>
						</li>
						<li class="nav-item">
							<a class="nav-link" href="/members2023"> 2023年度 </a>
						</li>
						<li class="nav-item">
							<a class="nav-link active" href="javascript:void(0);">
								2022年度
							</a>
						</li>
					</ul>

					<div class="table-responsive mb-5">
						<table class="table table-striped">
							<thead class="thead-dark">
								<tr>
									<th scope="col" class="text-nowrap">Number</th>
									<th scope="col" class="text-nowrap">Team</th>
									<th scope="col" class="text-nowrap">Player</th>
									<th scope="col" class="text-nowrap">Kana</th>
								</tr>
							</thead>
							<tbody>
								<template v-for="(player, index) in list">
									<tr
										v-if="player != null && player.number != ''"
										:key="'rank' + index"
									>
										<td class="text-nowrap">{{ player.number }}</td>
										<td class="text-nowrap">{{ player.team_name }}</td>
										<td class="text-nowrap">
											{{ player.player_name }}
										</td>
										<td class="text-nowrap">
											{{ player.player_name_kana }}
										</td>
									</tr>
								</template>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// @ is an alias to /src
import Vue from "vue";
import SubHeader from "@/components/common/SubHeader.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
Vue.use(Loading);

export default {
	name: "member2022",
	components: {
		SubHeader,
	},
	data() {
		return {
			isLoading: true,
			list: [],
			corks: [],
			g301: [],
			baseball: [],
			g501: [],
			cricket: [],
			group_id: null,
		};
	},
	mounted: function () {
		this.getData();
	},
	methods: {
		getData: function () {
			this.isLoading = true;
			let loader = this.$loading.show({
				// Optional parameters
				container: this.fullPage ? null : this.$refs.formContainer,
				canCancel: false,
				color: this.$store.state.primary_color,
				backgroundColor: "#666",
			});
			let params = {};
			this.axios
				.get(
					"https://script.google.com/macros/s/AKfycbyl3ew5BmH7EDp97XtRPMOnKFZC4DgG_Pgtiwni867NAEed9CghIbegw_C8PPDDU7o/exec",
					{
						params: params,
					}
				)
				.then((res) => {
					//console.log("res:" + JSON.stringify(res.data));
					this.list = res.data.list;
					// simulate AJAX
					setTimeout(() => {
						loader.hide();
						this.isLoading = false;
					}, 500);
				});
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h2 {
	font-size: 1.5rem;
	font-weight: bold;
}
h4 {
	font-size: 1rem;
	font-weight: bold;
}
.container {
	padding: 4rem 1rem;
	text-align: left;
}
a {
	text-decoration: underline;
}
td.score {
	text-align: right;
}
</style>
